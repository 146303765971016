import { Button, Card, Col, Row } from 'react-bootstrap';
import React from 'react';
import backgroundContact1 from 'assets/img/pictos/background-contact-1.jpg';
import backgroundContact2 from 'assets/img/pictos/background-contact-2.jpg';

const ContactCta = () => {
  return (
    <Row className="mt-lg-7 mt-4 justify-content-center">
      <Col lg={12} className="text-start">
        <h2 className="font-title-bold">Discuter avec la MNSPF</h2>
        <Row className="mt-2">
          <Col lg={6} className="text-start mt-3">
            <Card
              className="promotion-mnspf contact"
              style={{ height: '100%' }}
            >
              <Card.Body>
                <Row className="px-3 align-items-center h-100">
                  <Col
                    lg={4}
                    className="with-background h-100 d-none d-lg-block"
                    style={{ backgroundImage: `url('${backgroundContact1}')` }}
                  ></Col>
                  <Col lg={8} className="py-4 ps-lg-5 pe-lg-7">
                    <h5>Appeler un conseiller de la MNSPF</h5>
                    <p className="mb-0 mt-3">
                      Du lundi au jeudi, de 8h à 18h et le vendredi de 8h à 17h
                    </p>
                    <Row className="align-items-center mt-3">
                      <Col lg={12}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="30"
                          height="30"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path
                            d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"
                            fill="rgba(227,33,40,1)"
                          />
                        </svg>{' '}
                        <span className="phone ps-2">05 62 13 20 20</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} className="text-start mt-3">
            <Card
              className="promotion-mnspf contact"
              style={{ height: '100%' }}
            >
              <Card.Body>
                <Row className="px-3 align-items-center h-100">
                  <Col
                    lg={4}
                    className="with-background h-100 d-none d-lg-block"
                    style={{ backgroundImage: `url('${backgroundContact2}')` }}
                  ></Col>
                  <Col lg={8} className="py-4 ps-lg-5 pe-lg-7">
                    <h5>Demander à être rappelé plus tard</h5>
                    <Button
                      size={'sm'}
                      variant="falcon-primary"
                      className="me-2 mb-1 mt-3 mnspf-button demande-rappel"
                      onClick={() => {
                        window.location.href =
                          'https://mnspf.fr/contact#demande-rappel';
                      }}
                    >
                      Demander à être rappelé
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ContactCta;
