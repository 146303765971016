import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'simulators';

export async function IndexAnswereSimulator(simulator) {
  return modelService.IndexModel(`${model}/${simulator}/questions-public`);
}

export async function PostStatisticSimulator(simulator, formData) {
  return modelService.PostModel(`${model}/${simulator}/statistics`, formData);
}

export async function PutStatisticSimulator(simulator, statistic, formData) {
  return modelService.PutModel(
    `${model}/${simulator}/statistics/${statistic}`,
    formData
  );
}
