import { Button, Card, Col, Row } from 'react-bootstrap';
import React from 'react';
import checkRed from 'assets/img/pictos/check-red.png';

const Advantages = () => {
  let advantages = [
    {
      title: 'Des soins moins chers, partout en France',
      content:
        'Bénéficiez du reste à charge 0, du tiers payant et de 30% de réduction en moyenne chez plus de 15 000 professionnels de santé partenaires.'
    },
    /*{
      title: 'Un coaching santé personnalisé',
      content:
        'Sommeil, nutrition, bien-être : quelque soit votre problématique, nous avons les programmes pour vous aider.'
    },*/
    {
      title: 'Des garanties famille incroyables',
      content:
        'Allocations naissance, couverture gratuite, tarifs préférentiels : nous avons pensé à tout pour ceux qui comptent pour vous.'
    },
    {
      title: "L'accès à un médecin 24h/24 71/7",
      content:
        "Notre service de téléconsultation vous permet d'avoir un avis médical où que vous soyez, quand vous en avez besoin. Même le dimanche."
    },
    {
      title: 'Une assistance au-delà de la santé',
      content:
        "En cas d'événement imprévu, on prend soin de vous : garde d'enfant, aide à domicile... On s'occupe de tout, même de votre jardinage !"
    },
    {
      title: 'Un espace adhérent simplissime',
      content:
        'Pilotez votre santé et suivez vos remboursements depuis un seul espace accessible où que vous soyez.'
    }
  ];
  return (
    <Row className="mt-lg-7 mt-6 pt-lg-7 justify-content-center">
      <Col lg={12} className="text-start">
        <h2 className="font-title-bold">
          Nos avantages pour vous{' '}
          <span className="redhead">simplifier la vie</span>
        </h2>
        <Row>
          <Col lg={8} className="text-start mt-3 mb-lg-4 mb-2">
            Notre mission est claire : vous donner le{' '}
            <b>meilleur accès possible à la santé</b>. C'est pourquoi nous avons
            développé toute une galaxie de services et d'avantages
            supplémentaires pensés par nos adhérents, pour nos adhérents.
          </Col>
        </Row>
        <Row className="mt-2">
          {advantages.length > 0 && (
            <>
              {advantages.map((advantage, index) => (
                <Col key={index} lg={4} className="text-start mt-3">
                  <Card className="promotion-mnspf advantage">
                    <Card.Body>
                      <Row className="">
                        <Col xs={1} lg={2} className="p-0 text-center">
                          <img src={checkRed} className="checkred" />
                        </Col>
                        <Col xs={11} lg={10} className="text-start">
                          <p className="heading">{advantage.title}</p>
                          <p className="mb-0 pl-0 pl-lg-2">
                            {advantage.content}
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Row>
        <Row className="mt-4">
          <Col>
            <Button
              size={'sm'}
              variant="falcon-primary"
              className="me-2 mb-1 mnspf-button"
              onClick={() => {
                window.location.href = 'https://mnspf.fr/nos-avantages';
              }}
            >
              Découvrez nos avantages
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Advantages;
