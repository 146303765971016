import React, { useEffect, useState } from 'react';
import NavbarStandard from './NavbarStandard';
import Services from './Services';
import {
  IndexAnswereSimulator,
  PostStatisticSimulator
} from '../../services/SimulatorService';
import { toast } from 'react-toastify';
import Formulas from './Formulas';
import { IndexProcedures } from '../../services/ProcedureService';
import SimulatorHeading from './global/SimulatorHeading';
import loading from 'assets/img/animated-icons/loading.json';
import { Col, Row } from 'react-bootstrap';
import Lottie from 'lottie-react';

const Landing = () => {
  const [simulator, setSimulator] = useState(false);
  const [procedureCategories, setProcedureCategories] = useState([]);
  const [question, setQuestion] = useState(false);
  const [questionsHistory, setQuestionsHistory] = useState([]);
  const [questionsHistoryStat, setQuestionsHistoryStat] = useState([]);
  const [formulas, setFormulas] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [selectedExample, setSelectedExample] = useState(false);
  const [selectedFormula, setSelectedFormula] = useState(false);
  const [statisticId, setStatisticId] = useState(false);
  const [progress, setProgress] = useState(10);
  const [trigger] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const responseSimulator = await IndexAnswereSimulator('Mutuelle');
      if (responseSimulator.success === true) {
        const responseProcedures = await IndexProcedures('complementaires');
        if (responseProcedures.success === true) {
          setProcedureCategories(responseProcedures.data);
          if (responseProcedures.data.length > 0) {
            //setSelectedCategory(responseProcedures.data[0]);
          }
          setSimulator(responseSimulator.data);
          const entryFirstFiltered = responseSimulator.data.filter(obj => {
            return obj.simulator_entry === true;
          });
          setQuestion(entryFirstFiltered[0]);
          setQuestionsHistory([entryFirstFiltered[0]]);
          setIsLoading(false);
          const responseStatistics = await PostStatisticSimulator(1, {});
          if (responseStatistics.success === true) {
            setStatisticId(responseStatistics.data.id);
          }
        } else {
          setIsLoading(true);
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } else {
        setIsLoading(true);
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchData();
  }, [trigger]);

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <NavbarStandard />
      <div id="float-cta">
        <div
          className="cta"
          onClick={() =>
            (window.location.href = 'https://mnspf.fr/simuler-un-remboursement')
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="55"
            height="55"
          >
            <path
              d="M4 2h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm1 2v16h14V4H5zm2 2h10v4H7V6zm0 6h2v2H7v-2zm0 4h2v2H7v-2zm4-4h2v2h-2v-2zm0 4h2v2h-2v-2zm4-4h2v6h-2v-6z"
              fill="rgba(34,104,176,1)"
            />
          </svg>
          <span>Simuler mon remboursement</span>
        </div>
        <div
          className="cta"
          onClick={() =>
            (window.location.href = 'https://mnspf.fr/contact#demande-rappel')
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="55"
            height="55"
          >
            <path
              d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"
              fill="rgba(34,104,176,1)"
            />
          </svg>

          <span className="px-3">Se faire rappeler</span>
        </div>
        <div
          className="cta"
          onClick={() =>
            (window.location.href = 'https://mnspf.fr/foire-aux-questions')
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="55"
            height="55"
          >
            <path
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm2-1.645A3.502 3.502 0 0 0 12 6.5a3.501 3.501 0 0 0-3.433 2.813l1.962.393A1.5 1.5 0 1 1 12 11.5a1 1 0 0 0-1 1V14h2v-.645z"
              fill="rgba(34,104,176,1)"
            />
          </svg>
          <span>Besoin d'aide ?</span>
        </div>
        <div
          className="cta"
          onClick={() =>
            (window.location.href = 'https://mnspf.fr/contact#ecrire')
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="55"
            height="55"
          >
            <path
              d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"
              fill="rgba(34,104,176,1)"
            />
          </svg>
          <span>Nous écrire</span>
        </div>
      </div>
      {isLoading ? (
        <>
          <SimulatorHeading />
          <Row className="justify-content-center">
            <Col>
              <Lottie options={loadingOptions} width="220px" height="220px" />
            </Col>
          </Row>
        </>
      ) : (
        <>
          {formulas ? (
            <Formulas
              simulator={simulator}
              setQuestion={setQuestion}
              formulas={formulas}
              setFormulas={setFormulas}
              setQuestionsHistory={setQuestionsHistory}
              setProgress={setProgress}
              procedureCategories={procedureCategories}
              questionsHistoryStat={questionsHistoryStat}
              setQuestionsHistoryStat={setQuestionsHistoryStat}
              statisticId={statisticId}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedFormula={selectedFormula}
              setSelectedFormula={setSelectedFormula}
              selectedExample={selectedExample}
              setSelectedExample={setSelectedExample}
            />
          ) : (
            <Services
              simulator={simulator}
              question={question}
              setQuestion={setQuestion}
              questionsHistory={questionsHistory}
              setQuestionsHistory={setQuestionsHistory}
              progress={progress}
              setProgress={setProgress}
              setFormulas={setFormulas}
              statisticId={statisticId}
              questionsHistoryStat={questionsHistoryStat}
              setQuestionsHistoryStat={setQuestionsHistoryStat}
            />
          )}
        </>
      )}
    </>
  );
};

export default Landing;
