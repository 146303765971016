import React from 'react';
import { Row, Col, Card, ProgressBar } from 'react-bootstrap';
import className from 'classnames';
import Section from 'components/common/Section';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { PutStatisticSimulator } from '../../services/SimulatorService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SimulatorHeading from './global/SimulatorHeading';

const Services = ({
  simulator,
  question,
  setQuestion,
  questionsHistory,
  setQuestionsHistory,
  progress,
  setProgress,
  setFormulas,
  statisticId,
  questionsHistoryStat,
  setQuestionsHistoryStat
}) => {
  const switchQuestion = answer => {
    if (answer.children_question_id) {
      const filteredQuestion = simulator.filter(obj => {
        return obj.id === answer.children_question_id;
      });
      const checkIfFromFirstQuestion = simulator.filter(obj => {
        return (
          obj.id === answer.parent_question_id && obj.simulator_entry === true
        );
      });
      if (filteredQuestion[0]) {
        setQuestion(filteredQuestion[0]);
        let questionsHistoryTmp = [...questionsHistory];
        questionsHistoryTmp.push(filteredQuestion[0]);
        setQuestionsHistory(questionsHistoryTmp);

        let questionsHistoryStatTmp = [...questionsHistoryStat];
        if (checkIfFromFirstQuestion[0]) {
          questionsHistoryStatTmp.push(checkIfFromFirstQuestion[0]);
        }
        questionsHistoryStatTmp.push(answer);
        questionsHistoryStatTmp.push(filteredQuestion[0]);
        setQuestionsHistoryStat(questionsHistoryStatTmp);
        setProgressBar(filteredQuestion[0]);
        if (statisticId) {
          PutStatisticSimulator(1, statisticId, {
            history: questionsHistoryStatTmp
          });
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    } else if (answer.formulas_public && answer.formulas_public.length > 0) {
      setFormulas(answer.formulas_public);
      let questionsHistoryStatTmp = [...questionsHistoryStat];
      questionsHistoryStatTmp.push(answer);
      if (statisticId) {
        PutStatisticSimulator(1, statisticId, {
          history: questionsHistoryStatTmp
        });
        setQuestionsHistoryStat(questionsHistoryStatTmp);
      }
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };
  const backQuestion = question => {
    const filteredQuestion = simulator.filter(obj => {
      return obj.id === question.id;
    });
    if (filteredQuestion[0]) {
      setQuestion(filteredQuestion[0]);
      let questionsHistoryTmp = questionsHistory;
      let questionsHistoryStatTmp = [...questionsHistoryStat];
      questionsHistoryTmp.splice(-1, 1);
      questionsHistoryStatTmp.splice(-2, 2);
      setQuestionsHistory(questionsHistoryTmp);
      setQuestionsHistoryStat(questionsHistoryStatTmp);
      if (statisticId) {
        PutStatisticSimulator(1, statisticId, {
          history: questionsHistoryStatTmp
        });
      }
      setProgressBar(filteredQuestion[0]);
    } else {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };
  const setProgressBar = question => {
    if (question.simulator_entry === true) {
      setProgress(10);
    } else {
      let depth = maxDepth(question);
      let progressCalculation =
        (questionsHistory.length * 100) / (depth + questionsHistory.length);
      setProgress(progressCalculation);
    }
  };
  const maxDepth = function (root) {
    if (root === null || root === undefined) return 0;
    let depths = [0];
    root.answeres.map(answer => {
      let concernedQuestion = simulator.filter(obj => {
        return obj.id === answer.children_question_id;
      });
      if (concernedQuestion[0]) {
        depths.push(maxDepth(concernedQuestion[0]));
      }
    });
    return depths.length;
  };
  return (
    question && (
      <>
        {question.simulator_entry === true && <SimulatorHeading />}
        {questionsHistory && questionsHistory.length > 1 && (
          <Section bg="light" className="text-center pt-0">
            <Row className="mt-lg-5 mt-3 ps-3 ps-lg-0">
              <Col lg={4} className="text-start">
                <span
                  className="back-question cursor-pointer"
                  onClick={() =>
                    backQuestion(questionsHistory[questionsHistory.length - 2])
                  }
                >
                  <FontAwesomeIcon icon="arrow-left"></FontAwesomeIcon> Question
                  précédente
                </span>
              </Col>
            </Row>
          </Section>
        )}
        <Section bg="light" className="text-center pt-lg-6 pt-4">
          <h2 className="mb-4 question-title">{question.name}</h2>

          {question.answeres.map((answer, index) => (
            <Row
              className="mt-3 justify-content-center align-items-center"
              key={index}
            >
              <Col
                xs={9}
                md={6}
                lg={6}
                xl={4}
                xxl={4}
                className={className({ 'mt-0': index > 0 })}
              >
                <Card
                  className="card-span h-100 answer-card"
                  style={{ cursor: 'pointer' }}
                  onClick={() => switchQuestion(answer)}
                >
                  <Card.Body className="pt-3 pb-2">
                    <h5 className="mb-2">{answer.name}</h5>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ))}
          <div className="progress-container">
            <Col xs={10} lg={6} className="col-progress pb-5">
              <p className="text-center text-capitalize">
                PROGRESSION DU QUESTIONNAIRE
              </p>
              <ProgressBar now={progress} />
            </Col>
          </div>
        </Section>
      </>
    )
  );
};

Services.propTypes = {
  simulator: PropTypes.any,
  question: PropTypes.any,
  setQuestion: PropTypes.any,
  questionsHistory: PropTypes.array,
  setQuestionsHistory: PropTypes.any,
  progress: PropTypes.number,
  setProgress: PropTypes.any,
  setFormulas: PropTypes.any,
  statisticId: PropTypes.any,
  questionsHistoryStat: PropTypes.any,
  setQuestionsHistoryStat: PropTypes.func
};

export default Services;
