import React, { Fragment } from 'react';
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import {
  faChevronCircleLeft,
  faChevronCircleRight
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

const Simulator = ({
  title,
  procedureCategories,
  selectedCategory,
  setSelectedCategory,
  selectedFormula,
  setSelectedFormula,
  selectedExample,
  setSelectedExample,
  isLoading,
  quotationHrefLink,
  quotationAnchorId
}) => {
  const getFormulaKey = formulas => {
    let indexReturn = 0;
    formulas.map((formula, index) => {
      if (formula.id === selectedFormula.id) {
        indexReturn = index;
      }
    });
    return indexReturn;
  };
  const switchFormula = increment => {
    setSelectedFormula(
      selectedExample.formulas_public[
        getFormulaKey(selectedExample.formulas_public, selectedFormula) +
          increment
      ]
    );
  };
  document.onkeydown = checkKey;
  function checkKey(e) {
    if (selectedFormula) {
      if (e.keyCode == '37' || e.keyCode == '39') {
        let radios = document.getElementsByTagName('input');
        for (let i = 0; i < radios.length; i++) {
          radios[i].setAttribute('disabled', '');
          setTimeout(function () {
            radios[i].removeAttribute('disabled');
          }, 10);
        }
        if (e.keyCode == '37') {
          getFormulaKey(selectedExample.formulas_public, selectedFormula) > 0 &&
            switchFormula(-1);
        } else if (e.keyCode == '39') {
          getFormulaKey(selectedExample.formulas_public, selectedFormula) !==
            selectedExample.formulas_public.length - 1 && switchFormula(1);
        }
      }
    }
  }
  return (
    <Row className="simulator mx-1">
      {title && (
        <Col
          lg={7}
          className="text-start mb-lg-5 mt-lg-5 mt-5 mb-3"
          style={{ paddingLeft: '0' }}
        >
          <h2 className="font-title-bold">{title}</h2>
        </Col>
      )}
      {isLoading ? (
        <Row className="mt-0">
          <Col lg={12} className="mt-lg-0 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      ) : (
        <>
          <Col lg={9} className="text-start shadow">
            <Row className="mt-2">
              <Col lg={12}>
                <Row>
                  <Col lg={12} className="header-section">
                    1. Choisissez une catégorie d'acte médical
                  </Col>
                  <Col lg={12}>
                    <Row className="bordered py-3">
                      {procedureCategories.map((category, indexCategory) => (
                        <Fragment key={indexCategory}>
                          <Col
                            xs={6}
                            xl={3}
                            className="text-center cursor-pointer liste-actes"
                          >
                            <Row className="py-0 px-2">
                              <div>
                                <div
                                  className={classNames('acte-container', {
                                    active:
                                      selectedCategory &&
                                      selectedCategory.id === category.id
                                  })}
                                  onClick={() => {
                                    setSelectedCategory(category);
                                    setSelectedExample(false);
                                    setSelectedFormula(false);
                                    if (isMobile) {
                                      setTimeout(function () {
                                        document
                                          .getElementById('example-container')
                                          .scrollIntoView();
                                      }, 20);
                                    }
                                  }}
                                >
                                  <Col sm={12} className="text-center">
                                    <img
                                      src={
                                        category.thumbnail
                                          ? category.thumbnail
                                          : 'https://via.placeholder.com/150'
                                      }
                                      className="mb-0"
                                    />
                                  </Col>
                                  <Col
                                    sm={12}
                                    className="text-center mt-3 acte-name"
                                  >
                                    {category.name}
                                  </Col>
                                </div>
                              </div>
                            </Row>
                          </Col>
                        </Fragment>
                      ))}
                    </Row>
                  </Col>
                </Row>
                <Row className="bordered" id="example-container">
                  <Col
                    lg={12}
                    className="header-section"
                    style={{
                      borderTopLeftRadius: '0',
                      borderTopRightRadius: '0'
                    }}
                  >
                    2. Sélectionnez un exemple
                  </Col>
                  {selectedCategory && (
                    <Col lg={12} className="my-4">
                      <Row>
                        <Col lg={12} className="ps-4">
                          {selectedCategory.examples.map(
                            (example, indexExample) => {
                              return (
                                <Form.Check
                                  key={indexExample}
                                  type="radio"
                                  id={`radio-${example.id}`}
                                  label={
                                    selectedExample &&
                                    selectedExample.id === example.id ? (
                                      <span className="example-active">
                                        {example.name}
                                      </span>
                                    ) : (
                                      example.name
                                    )
                                  }
                                  value={example.id}
                                  style={{ cursor: 'pointer' }}
                                  name="radio"
                                  checked={
                                    selectedExample &&
                                    selectedExample.id === example.id
                                  }
                                  onChange={() => {
                                    setSelectedExample(example);
                                    if (isMobile) {
                                      setTimeout(function () {
                                        document
                                          .getElementById('formula-container')
                                          .scrollIntoView();
                                      }, 20);
                                    }
                                  }}
                                />
                              );
                            }
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Row
                  className="bordered bordered-radius-bottom"
                  id="formula-container"
                >
                  <Col
                    lg={12}
                    className="header-section"
                    style={{
                      borderTopLeftRadius: '0',
                      borderTopRightRadius: '0'
                    }}
                  >
                    3. Sélectionnez une offre de la MNSPF
                  </Col>
                  {selectedExample && (
                    <Col
                      lg={12}
                      className="formulas-select-container my-3 ps-lg-4 mb-4"
                    >
                      <Row>
                        {selectedExample.formulas_public.map(
                          (formula, indexFormula) => (
                            <Col
                              lg={2}
                              xs={6}
                              key={indexFormula}
                              className="text-center cursor-pointer mt-2 mt-lg-2"
                              onClick={() => {
                                setSelectedFormula(formula);
                                if (isMobile) {
                                  setTimeout(function () {
                                    document
                                      .getElementById('result-container')
                                      .scrollIntoView();
                                  }, 20);
                                }
                              }}
                            >
                              <div
                                className={classNames('formula-select', {
                                  active:
                                    selectedFormula &&
                                    selectedFormula.id === formula.id
                                })}
                              >
                                <span>{formula.title}</span>
                              </div>
                            </Col>
                          )
                        )}
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            lg={3}
            className="text-start mt-2 result-container "
            id="result-container"
          >
            {selectedFormula ? (
              <Row className="text-center result-sub-container shadow">
                <Col sm={12} className="header-section">
                  {getFormulaKey(
                    selectedExample.formulas_public,
                    selectedFormula
                  ) > 0 && (
                    <span>
                      <FontAwesomeIcon
                        icon={faChevronCircleLeft}
                        className="float-start mt-0 cursor-pointer"
                        style={{ fontSize: '1.3rem' }}
                        onClick={() => switchFormula(-1)}
                      />
                    </span>
                  )}
                  {selectedFormula.title}
                  {getFormulaKey(
                    selectedExample.formulas_public,
                    selectedFormula
                  ) !==
                    selectedExample.formulas_public.length - 1 && (
                    <span>
                      <FontAwesomeIcon
                        icon={faChevronCircleRight}
                        className="float-end mt-0 cursor-pointer"
                        style={{ fontSize: '1.3rem' }}
                        onClick={() => switchFormula(1)}
                      />
                    </span>
                  )}
                </Col>
                <Col sm={12} className="price-section pt-4">
                  <div className="d-block title">Prix moyen de l'acte</div>
                  <div className="d-block price">
                    {selectedExample.average_price
                      .toFixed(3)
                      .slice(0, -1)
                      .replace('.', ',')}
                    €
                  </div>
                  <div className="divider px-3 mt-4">
                    <hr />
                  </div>
                </Col>
                <Col sm={12} className="price-section pt-4">
                  <div className="d-block title smaller-title">
                    Prise en charge sécurité sociale
                  </div>
                  <div className="d-block price">
                    {selectedExample.average_support
                      .toFixed(3)
                      .slice(0, -1)
                      .toString()
                      .replace('.', ',')}
                    €
                  </div>
                  <div className="divider px-3 mt-4">
                    <hr />
                  </div>
                </Col>
                <Col sm={12} className="price-section pt-4">
                  <div className="d-block title">Prise en charge MNSPF</div>
                  <div className="d-block price">
                    {selectedExample.formulas_public
                      .filter(obj => {
                        return obj.id === selectedFormula.id;
                      })[0]
                      .pivot.coverage.toFixed(3)
                      .slice(0, -1)
                      .toString()
                      .replace('.', ',')}
                    €
                  </div>
                  <div className="divider px-3 mt-4">
                    <hr />
                  </div>
                </Col>
                <Col sm={12} className="price-section pt-4">
                  <div className="d-block title">Reste à charge</div>
                  <div className="d-block price">
                    {(
                      selectedExample.average_price -
                      selectedExample.average_support -
                      selectedExample.formulas_public.filter(obj => {
                        return obj.id === selectedFormula.id;
                      })[0].pivot.coverage
                    )
                      .toFixed(3)
                      .slice(0, -1)
                      .toString()
                      .replace('.', ',')}
                    €
                  </div>
                  <div className="divider px-3 mt-4">
                    <hr />
                  </div>
                </Col>
                <Col sm={12} className="py-3 quote-section mb-2 mb-lg-0">
                  <div className="d-block">
                    {quotationAnchorId ? (
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          document
                            .getElementById(quotationAnchorId)
                            .scrollIntoView();
                        }}
                      >
                        Demandez un devis
                      </a>
                    ) : (
                      quotationHrefLink && (
                        <a target="_parent" href={quotationHrefLink}>
                          Demandez un devis
                        </a>
                      )
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row
                className="text-center result-sub-container"
                style={{
                  background: '#F9F9F9',
                  position: 'relative',
                  height: '100%'
                }}
              >
                <Col lg={12} className="wait-col shadow">
                  <div className="header-section center-vertical">
                    4. Découvrez vos remboursements et le montant du reste à
                    charge
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </>
      )}
    </Row>
  );
};

Simulator.propTypes = {
  title: PropTypes.any,
  procedureCategories: PropTypes.array,
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.func,
  selectedFormula: PropTypes.any,
  setSelectedFormula: PropTypes.func,
  selectedExample: PropTypes.any,
  setSelectedExample: PropTypes.func,
  isLoading: PropTypes.bool,
  quotationHrefLink: PropTypes.string,
  quotationAnchorId: PropTypes.string,
  link: PropTypes.any
};

export default Simulator;
