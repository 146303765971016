import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'procedure-categories';

export async function IndexProcedures(category) {
  if (category !== undefined && category !== null) {
    return modelService.IndexModel(`${model}?formula_category=${category}`);
  } else {
    return modelService.IndexModel(model);
  }
}
