import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { IndexProcedures } from '../../services/ProcedureService';
import CompareOffers from './CompareOffers';
import { IndexFormulas } from '../../services/FormulaService';

const ComparatorFrame = ({ category }) => {
  const [formulas, setFormulas] = useState([]);
  const [procedureCategories, setProcedureCategories] = useState([]);
  const [trigger] = useState(0);

  function sendHeight() {
    if (parent.postMessage) {
      let height = document.getElementById('main').offsetHeight;
      parent.postMessage(height, '*');
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const responseProcedures = await IndexProcedures(category);
      if (responseProcedures.success === true) {
        setProcedureCategories(responseProcedures.data);
        const responseFormulas = await IndexFormulas(category);
        if (responseFormulas.success === true) {
          setFormulas(responseFormulas.data);
          setInterval(function () {
            sendHeight();
          }, 200);
        } else {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchData();
  }, [trigger]);

  return (
    <CompareOffers
      formulas={formulas}
      procedureCategories={procedureCategories}
      title={null}
      link="https://mnspf.fr/contact"
      proceduresCategory={category}
    />
  );
};

export default ComparatorFrame;

ComparatorFrame.propTypes = {
  category: PropTypes.string
};
