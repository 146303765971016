import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'formulas';

export async function IndexFormulas(category) {
  if (category !== undefined && category !== null) {
    return modelService.IndexModel(`${model}?category=${category}`);
  } else {
    return modelService.IndexModel(`${model}`);
  }
}
