import { Button, Col, Row, Table } from 'react-bootstrap';
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';

const CompareOffers = ({
  formulas,
  procedureCategories,
  title,
  link,
  proceduresCategory
}) => {
  const [checkScrollTable, setCheckScrollTable] = useState(false);
  const [checkScrollTableMax, setCheckScrollTableMax] = useState(false);
  const checkScrollTableFunction = () => {
    setCheckScrollTable(
      document.getElementsByClassName('table-responsive').length > 0 &&
        document.getElementsByClassName('table-responsive')[0].scrollWidth >
          document.getElementsByClassName('table-responsive')[0].clientWidth
    );
  };
  setTimeout(function () {
    checkScrollTableFunction();
  }, 800);
  window.onresize = function () {
    checkScrollTableFunction();
  };
  setTimeout(function () {
    document.getElementsByClassName('table-responsive')[0].onscroll = () => {
      if (
        document.getElementsByClassName('table-responsive')[0].scrollLeft >=
        document.getElementsByClassName('table-responsive')[0].scrollWidth -
          document.getElementsByClassName('table-responsive')[0].clientWidth
      ) {
        setCheckScrollTableMax(true);
      } else {
        setCheckScrollTableMax(false);
      }
    };
  }, 1200);

  return (
    <>
      <Row
        className={classNames('justify-content-center', {
          'mt-2 mt-lg-6': title !== null && title !== undefined
        })}
        id="compare"
      >
        <Col lg={12} className="text-start">
          {title !== null && title !== undefined && <>{title}</>}
          <Row>
            {checkScrollTable === true && checkScrollTableMax && (
              <Col
                xs={12}
                className="text-start cursor-pointer mt-4"
                onClick={() => {
                  document
                    .getElementsByClassName('table-responsive')[0]
                    .scrollTo({
                      top: 0,
                      left: 0,
                      behavior: 'smooth'
                    });
                }}
              >
                <FontAwesomeIcon
                  style={{ fontSize: '24px' }}
                  icon={faArrowLeft}
                ></FontAwesomeIcon>
              </Col>
            )}
            {checkScrollTable === true && !checkScrollTableMax && (
              <Col
                xs={12}
                className="text-end cursor-pointer mt-4"
                onClick={() => {
                  let scrollAmount = 0;
                  let scrollMax =
                    document.getElementsByClassName('table-responsive')[0]
                      .clientWidth;
                  document
                    .getElementsByClassName('table-responsive')[0]
                    .scrollTo({
                      top: 0,
                      left: Math.max((scrollAmount += 500), scrollMax),
                      behavior: 'smooth'
                    });
                }}
              >
                <FontAwesomeIcon
                  style={{ fontSize: '24px' }}
                  icon={faArrowRight}
                ></FontAwesomeIcon>
              </Col>
            )}
          </Row>

          <Table
            id="table-formulas"
            responsive
            className="mt-lg-6 mt-4 table-compare"
          >
            <thead>
              <tr className="formulas-row">
                <th scope="col"></th>
                {formulas.map((formula, index) => (
                  <Fragment key={index}>
                    <th
                      scope="col"
                      className="text-center formula"
                      valign="middle"
                    >
                      <span className="d-block mb-2">{formula.title}</span>
                      {formula.price !== undefined &&
                        formula.price !== null && (
                          <span className="price-from mb-2">
                            à partir de {formula.price}€
                          </span>
                        )}
                    </th>
                    {index + 1 !== formula.length && <th></th>}
                  </Fragment>
                ))}
              </tr>
            </thead>
            {procedureCategories && (
              <>
                {procedureCategories.map((category, indexCategory) => (
                  <tbody key={indexCategory}>
                    {proceduresCategory !== 'surcomplementaires' && (
                      <tr className="header-row" key={indexCategory}>
                        <td className="text-uppercase">
                          {category.name}{' '}
                          {category.name !== 'Bien-être' && (
                            <span
                              style={{
                                fontWeight: 400,
                                textTransform: ' none',
                                fontSize: '12px'
                              }}
                            >
                              <i> - Remboursement régime obligatoire + MNSPF</i>
                            </span>
                          )}
                        </td>
                        {formulas.map((formula, index) => (
                          <Fragment key={index}>
                            <td></td>
                            {index + 1 !== formulas.length && <td></td>}
                          </Fragment>
                        ))}
                      </tr>
                    )}
                    {category.procedures.map((procedure, indexProcedure) => (
                      <tr key={indexProcedure}>
                        <td className="bordered">{parse(procedure.name)}</td>
                        {formulas.map((formula, index) => {
                          return (
                            procedure.formulas_public.filter(obj => {
                              return obj.id === formula.id;
                            }).length > 0 && (
                              <Fragment key={index}>
                                <td
                                  key={index}
                                  className="text-center bordered"
                                >
                                  {
                                    procedure.formulas_public.filter(obj => {
                                      return obj.id === formula.id;
                                    })[0].pivot.coverage
                                  }
                                </td>
                                {index + 1 !== formulas.length && <td></td>}
                              </Fragment>
                            )
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                ))}
                <tbody className="no-bottom-border">
                  <tr>
                    <td></td>
                    {formulas.map((formula, index) => (
                      <Fragment key={index}>
                        <td key={index} className="text-center">
                          <span className="d-block w-100 mt-3">
                            {formula.file_url && (
                              <a
                                href={formula.file_url}
                                rel="noreferrer"
                                target="_blank"
                                key={index}
                                className="download-link"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="20"
                                  height="20"
                                  className="d-none d-lg-inline"
                                >
                                  <path
                                    d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zM14 9h5l-7 7-7-7h5V3h4v6z"
                                    fill="rgba(22,66,113,1)"
                                  />
                                </svg>
                                Télécharger la plaquette
                              </a>
                            )}
                          </span>
                          <span className="d-block w-100 mt-3">
                            <Button
                              variant="outline-primary"
                              className="me-0 mb-0 mnspf-button ask-quote d-none"
                              size="sm"
                              onClick={() => {
                                if (link !== null && link !== undefined) {
                                  parent.location.href = link;
                                } else {
                                  document
                                    .getElementById('formcontact')
                                    .scrollIntoView();
                                }
                              }}
                            >
                              Demandez un devis
                            </Button>
                          </span>
                        </td>
                        {index + 1 !== formulas.length && <td></td>}
                      </Fragment>
                    ))}
                  </tr>
                </tbody>
              </>
            )}
          </Table>
        </Col>
      </Row>
    </>
  );
};

CompareOffers.propTypes = {
  formulas: PropTypes.array,
  procedureCategories: PropTypes.array,
  title: PropTypes.any,
  link: PropTypes.any,
  proceduresCategory: PropTypes.any
};

export default CompareOffers;
