import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import handleNavbarTransparency from 'helpers/handleNavbarTransparency';
import AppContext from 'context/Context';
import IconButton from '../common/IconButton';
import logoMnspf from 'assets/img/logo-mnspf.png';

const NavbarStandard = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);
  const [navbarCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      expand="lg"
      className={classNames('navbar-standard navbar-theme box-shadow', {
        'bg-100': !navbarCollapsed && isDark,
        'bg-dark': !navbarCollapsed && !isDark
      })}
    >
      <Container>
        <Navbar.Brand
          as={Link}
          to="#"
          onClick={() => {
            window.location.href = 'https://mnspf.fr';
          }}
        >
          <img
            className="logo-mnspf"
            alt="Logo MNPSF - Mutuelle Nationale des Sapeurs Pompiers de France"
            src={logoMnspf}
            width={100}
            height={43}
          />
        </Navbar.Brand>
        <IconButton
          className="me-lg-2 mnspf-button"
          variant="falcon-default"
          icon="home"
          transform="shrink-3"
          size={'sm'}
          onClick={() => {
            window.location.href = 'https://mnspf.fr';
          }}
        >
          Revenir à l'accueil
        </IconButton>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
