import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Section from 'components/common/Section';

const SimulatorHeading = () => {
  return (
    <Section className="text-center py-lg-6 py-4" id="header-simulator">
      <h1>
        Trouver <span className="redhead">l'offre idéale</span> pour ma
        situation
      </h1>
      <Row className="align-items-center justify-content-center mt-3">
        <Col xl={6} lg={6}>
          <p className="text-center mb-0">
            Pour vous orienter vers les offres les plus adaptées à votre
            situation, nous vous avons concocté un mini questionnaire.
            <br />
            <br />
            <b>Prêt ? C'est parti !</b>
          </p>
        </Col>
      </Row>
    </Section>
  );
};

export default SimulatorHeading;
