import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';

import ErrorLayout from './ErrorLayout';
import Landing from '../components/qwenty/Landing';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import Error404 from 'components/errors/Error404';
import AppContext from 'context/Context';
import SimulatorFrame from '../components/qwenty/SimulatorFrame';
import ComparatorFrame from '../components/qwenty/ComparatorFrame';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/simulator-frame" element={<SimulatorFrame />} />
        <Route
          path="/comparator-complementaires-frame"
          element={<ComparatorFrame category="complementaires" />}
        />
        <Route
          path="/comparator-surcomplementaires-frame"
          element={<ComparatorFrame category="surcomplementaires" />}
        />
        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
        </Route>
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
