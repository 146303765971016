import { Card, Col, Row } from 'react-bootstrap';
import React from 'react';
import starImg from 'assets/img/star.png';

const Promotion = () => {
  return (
    <Row className="mt-lg-5 mt-3 justify-content-center">
      <Col lg={12}>
        <Card className="promotion-mnspf">
          <Card.Body>
            <Row className="align-items-center">
              <Col
                lg={1}
                className="d-none d-lg-block text-lg-center text-start mb-3 mb-lg-0 star-container"
              >
                <img src={starImg} className="star" />
              </Col>
              <Col lg={11} className="text-start">
                <h5>
                  Vous êtes peut-être éligible à des avantages supplémentaires !
                </h5>
                <p className="mb-0 mt-2">
                  La Mutuelle Nationale des Sapeurs-Pompiers de France possède
                  des accords spécifiques avec le Service Départemental
                  d'Incendie et de Secours (SDIS) de certains départements qui
                  vous offre des avantages supplémentaires. Contactez-nous pour
                  en profiter !
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Promotion;
