import HttpService from './HttpService';
const http = new HttpService();
export default class ModelService {
  IndexModel = async url => {
    return http
      .getData(url)
      .then(data => {
        return data;
      })
      .catch(error => {
        return error;
      });
  };

  ShowModel = async url => {
    return http
      .getData(url)
      .then(data => {
        return data;
      })
      .catch(error => {
        return error;
      });
  };

  PostModel = async (url, formData) => {
    return http
      .postData(formData, url)
      .then(data => {
        return data;
      })
      .catch(error => {
        return error;
      });
  };

  PutModel = async (url, formData) => {
    return http
      .putData(formData, url)
      .then(data => {
        return data;
      })
      .catch(error => {
        return error;
      });
  };

  DeleteModel = async url => {
    return http
      .deleteData(url)
      .then(data => {
        return data;
      })
      .catch(error => {
        return error;
      });
  };
}
