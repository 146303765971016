import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { IndexProcedures } from '../../services/ProcedureService';
import Simulator from './Simulator';

const SimulatorFrame = () => {
  const [procedureCategories, setProcedureCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [selectedExample, setSelectedExample] = useState(false);
  const [selectedFormula, setSelectedFormula] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [trigger] = useState(0);

  function sendHeight() {
    if (parent.postMessage) {
      let height = document.getElementById('main').offsetHeight;
      parent.postMessage(height, '*');
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const responseProcedures = await IndexProcedures('complementaires');
      if (responseProcedures.success === true) {
        setProcedureCategories(responseProcedures.data);
        setIsLoading(false);
        setInterval(function () {
          sendHeight();
        }, 200);
      } else {
        setIsLoading(true);
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    fetchData();
  }, [trigger]);

  return (
    <Simulator
      title=""
      procedureCategories={procedureCategories}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      selectedFormula={selectedFormula}
      setSelectedFormula={setSelectedFormula}
      selectedExample={selectedExample}
      setSelectedExample={setSelectedExample}
      isLoading={isLoading}
      quotationHrefLink="https://mnspf.fr/contact#demande-devis"
    />
  );
};

export default SimulatorFrame;
