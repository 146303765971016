import { Col, Row } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import Section from '../common/Section';
import Promotion from './Promotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons/faChevronCircleDown';
import tadam1 from 'assets/img/tadam1.png';
import tadam2 from 'assets/img/tadam2.png';

const Offers = ({ formulas }) => {
  return (
    <>
      <Section className="text-center py-lg-6 py-4" id="header-simulator">
        <Row className="align-items-center justify-content-center">
          <Col lg={4} className="d-none d-lg-block text-end">
            <img className="tadam-img" src={tadam1} />
          </Col>
          <Col xs={12} lg={4}>
            <h1>
              <span className="redhead">Tadaaaam !</span>
            </h1>
            <p className="text-center mb-0">
              Découvrez nos offres, juste pour vous !
            </p>
          </Col>
          <Col lg={4} className="d-none d-lg-block text-start">
            <img className="tadam-img" src={tadam2} />
          </Col>
        </Row>
        <Row className="mt-lg-6 mt-5 justify-content-center">
          <Col lg={12}>
            <Row className="formulas-container justify-content-center">
              {formulas.map((formula, index) => (
                <Col key={index} lg={4} className="mb-4">
                  {formula.logo_url !== undefined &&
                    formula.logo_url !== null && (
                      <img src={formula.logo_url} className="mb-4" />
                    )}
                  <h3 className="formula-title mb-3">{formula.title}</h3>
                  <p>{formula.content}</p>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Promotion />
        <Row
          className="mt-5 justify-content-center"
          onClick={() => {
            document.getElementById('compare').scrollIntoView();
          }}
        >
          <Col lg={12} className="text-center cursor-pointer compare-link">
            <p className="mb-2">
              <b>COMPARER LES OFFRES</b>
            </p>
            <p>
              <FontAwesomeIcon
                style={{ fontSize: '25px' }}
                icon={faChevronCircleDown}
              />
            </p>
          </Col>
        </Row>
      </Section>
    </>
  );
};

Offers.propTypes = {
  formulas: PropTypes.array
};

export default Offers;
