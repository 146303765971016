import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Section from 'components/common/Section';
import PropTypes from 'prop-types';
import Simulator from './Simulator';
import Advantages from './Advantages';
import Offers from './Offers';
import CompareOffers from './CompareOffers';
import ContactCta from './ContactCta';

const Formulas = ({
  formulas,
  procedureCategories,
  selectedCategory,
  setSelectedCategory,
  selectedFormula,
  setSelectedFormula,
  selectedExample,
  setSelectedExample
}) => {
  /* const resetSimulator = () => {
       setFormulas(false);
       const entryFirstFiltered = simulator.filter(obj => {
         return obj.simulator_entry === true;
       });
       setQuestion(entryFirstFiltered[0]);
       setQuestionsHistory([entryFirstFiltered[0]]);
       setProgress(10);
       setQuestionsHistoryStat([entryFirstFiltered[0]]);
       if (statisticId) {
         PutStatisticSimulator(1, statisticId, {
           history: [entryFirstFiltered[0]]
         });
       }
     };*/
  return (
    formulas && (
      <>
        <Offers formulas={formulas} />
        <Section bg="light" className="text-center pt-5 pb-4 pb-lg-6">
          {/*<Row className="align-items-center justify-content-center mb-5">
            <Col lg={12} className="text-start">
              <IconButton
                className="me-2 mb-1 no-background"
                variant="falcon-default"
                icon="chevron-left"
                size="sm"
                transform="shrink-3"
                onClick={() => resetSimulator()}
              >
                Modifier mes réponses
              </IconButton>
            </Col>
          </Row>*/}
          <CompareOffers
            formulas={formulas}
            procedureCategories={procedureCategories}
            title={
              <h2 className="font-title-bold">
                <span className="redhead">Comparez les offres</span> que nous
                vous proposons
              </h2>
            }
          />
          <Row className="mt-lg-7 mt-0 justify-content-center">
            <Col lg={12} className="text-start">
              <Simulator
                title={
                  <>
                    En trois clics,{' '}
                    <span className="redhead">
                      simulez le montant de votre reste à charge
                    </span>
                  </>
                }
                procedureCategories={procedureCategories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedFormula={selectedFormula}
                setSelectedFormula={setSelectedFormula}
                selectedExample={selectedExample}
                setSelectedExample={setSelectedExample}
                quotationAnchorId="formcontact"
              />
            </Col>
          </Row>
          <Advantages />
        </Section>
        <Section className="text-center pt-2 contact-cta pb-lg-7 pb-4">
          <ContactCta />
        </Section>
        {/* Bouton de redirection vers le site vitrine*/}
        <Section bg="light" className="text-center pt-lg-7 pt-0">
          <Col lg={12} className="text-start">
            <h2 className="font-title-bold">
              Demander votre <span className="redhead">devis</span> à la MNSPF
            </h2>

            <Button
              size={'sm'}
              variant="falcon-primary"
              className="me-2 mb-1 mt-3 mnspf-button demande-rappel"
              onClick={() => {
                window.location.href = 'https://mnspf.fr/contact#demande-devis';
              }}
            >
              Demander un devis
            </Button>
          </Col>
        </Section>

        {/*   <Section bg="light" className="text-center pt-lg-5 pt-0">
          <ContactForm questionsHistoryStat={questionsHistoryStat} />
        </Section>*/}
      </>
    )
  );
};

Formulas.propTypes = {
  simulator: PropTypes.any,
  setQuestion: PropTypes.any,
  formulas: PropTypes.array,
  setFormulas: PropTypes.any,
  setQuestionsHistory: PropTypes.any,
  setProgress: PropTypes.any,
  procedureCategories: PropTypes.array,
  questionsHistoryStat: PropTypes.array,
  setQuestionsHistoryStat: PropTypes.any,
  statisticId: PropTypes.any,
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.func,
  selectedFormula: PropTypes.any,
  setSelectedFormula: PropTypes.func,
  selectedExample: PropTypes.any,
  setSelectedExample: PropTypes.func
};

export default Formulas;
